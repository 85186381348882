import React from "react";

import { Icon } from '@types';

const XIcon: Icon = ({ fill = "white", ...props }) => (
  <svg
    width="16"
    height="13"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z"
      fill={fill}
    />
  </svg>
);

export default XIcon;
