import React from 'react';
import { Icon } from '@types';

const SGDBIcon: Icon = ({ fill = "white", size = 14 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 198 108">
    <path fill={fill} d="M186 24v-9H19v81h12V24h155z"/>
    <path fill={fill} d="M186.22 30H37v78h161V30h-11.78z"/>
    <path fill={fill} d="M0 0v84h13V9h161V0H0z"/>
  </svg>
)
export default SGDBIcon;
