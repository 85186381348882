import React from 'react';
import { useColorMode } from 'theme-ui';

export default function Logo({ fill }) {
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';

  return (isDark ?
    <svg height="30px" viewBox="0 0 122 60">
      <path fill={fill} d="M6 0H0v46h6V4h100V0H6z"/>
      <path fill={fill} d="M14 6H8v45h6V10h96V6H14z"/>
      <path fill={fill} d="M22 12h-6v43h6V16h93v-4H22z"/>
      <path fill={fill} d="M24 18h98v42H24z"/>
    </svg>
    :
    <svg height="30px" viewBox="0 0 50 24">
      <path fill="#305b79" d="M0 0h41v18H0V0z"/>
      <path fill="#4787b4" d="M3 2h41v18H3V2z"/>
      <path fill="#5fb4f0" d="M6 4h41v18H6V4z"/>
      <path fill="#3a6e92" d="M9 6h41v18H9V6z"/>
    </svg>
  );
}